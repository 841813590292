.color-nav {
  color: white !important;
}

.color-nav-md {
  color: black !important;
}

.flex-nav {
  flex: 1 0 auto;
}
.pointer {
  cursor: pointer;
}

.left-drop {
  left: -20px !important;
  width: max-content;
}

.remove-nav {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.it-header-center-content-wrapper {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.it-header-slim-wrapper-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
